'use strict';

angular.module('enervexSalesappApp').controller('DiameterCtrl', function($scope, Diameter, $stateParams, Flash, $state, _, Util) {
	$scope.errors = {};
	$scope.ctx = {
		formChanged: false
	}
	if ($stateParams.id == "new") {
		$scope.diameter = {
		}
	} else {
		Diameter.get({
			id: $stateParams.id
		}).$promise.then(function(diameter) {
			$scope.diameter = diameter;
			fetchMaterials()
		})
	}
	$scope.save = function(form) {
		$scope.submitted = true;
		if (form.$valid) {
			Flash.clear();
			var payload = _.clone($scope.diameter);
			payload = _.omit(payload, "__v");
			if (!$scope.diameter._id) {
				Diameter.save(payload).$promise.then(function(result) {
					$scope.success = true;
					Flash.create('success', '<strong>Success!</strong> Successfully created diameter.', 0, {}, true);
					$state.go("diameter", {
						id: result._id
					}, {
						inherit: false,
						reload: true
					})
				}).catch(function(err) {
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			} else {
				Diameter.update({
					id: $scope.diameter._id
				}, payload).$promise.then(function(diameter) {
					$scope.success = true;
					Flash.create('success', '<strong>Success!</strong> Successfully updated diameter.', 0, {}, true);
				}).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			}
		} else {
			$scope.errors = {};
			$scope.errors.other = 'Problem saving';
			var message = "Please fix validation errors";
			Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
		}
	};
});
